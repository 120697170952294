import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { useAuth } from "gatsby-theme-firebase"
import LoginModal from "../components/loginModal"
import LectureList from "../components/lectureList"
import "../styles/content.scss"
import { firestore } from "gatsby-theme-firebase"
import * as qs from "query-string"
import { navigate } from "gatsby"

export default ({ pageContext: { course }, location, history }) => {
  const { shortName, title, slug } = course
  const { default: publicLectureInfo } = require(`../lectures/${shortName}`)
  const { isLoading, isLoggedIn, profile } = useAuth()
  const [userAccess, setUserAccess] = useState(false)

  const [chosenLecture, chooseLecture] = useState(publicLectureInfo[0])

  let user =
    typeof window !== "undefined" && localStorage.getItem("MM_User")
  if (user == null && !userAccess) {
    if (profile && profile.uid)
      firestore
        .collection(`users`)
        .doc(profile.uid)
        .get()
        .then(foundUser => {
          console.log("pinged DB")
          user = foundUser.data()
          typeof window !== "undefined" &&
            localStorage.setItem("MM_User", JSON.stringify(user))
          if (!userAccess && user && user.tier > 0) setUserAccess(true)
          //user is on subscription
          if (
            !userAccess &&
            user &&
            user.products &&
            user.products[shortName]
          ) {
            //user has course
            setUserAccess(true)
          }
        })
  } else {
    user = JSON.parse(user)
    if (!userAccess && user && user.tier > 0) setUserAccess(true)
    if (!userAccess && user && user.products && user.products[shortName]) {
      //user has course
      setUserAccess(true)
    }
  }

  let lectureInfo = publicLectureInfo

  useEffect(() => {}, [userAccess])

  useEffect(() => {
    const this_id = Number(qs.parse(location.search)._id)
    if (this_id && lectureInfo) {
      const foundLecture = lectureInfo.find(({ _id }) => _id === this_id)
      if (foundLecture) chooseLecture(foundLecture)
    }
  }, [])

  useEffect(() => {
    if (chosenLecture && chosenLecture._id)
      navigate(`${location.pathname}?_id=${chosenLecture._id}`)
  }, [chosenLecture])

  let videoContent
  const staticContent = (
    <>
      <SEO title={title} />
      <div className="content-hero">
        <div className="headline">{title}</div>
      </div>
    </>
  )
  if (isLoading) {
    return <Layout>{staticContent}</Layout>
  }

  if (userAccess || chosenLecture._id === 0) {
    videoContent = (
      <div className="video-lecture">
        <div className="previous-button"></div>
        {chosenLecture.vid_link && chosenLecture.vid_link !== "none" && (
          <div className="video-frame">
            <iframe
              width="100%"
              height="100%"
              src={`https://player.vimeo.com/video/${chosenLecture.vid_link}`}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </div>
        )}
        <div className="written-lecture">{chosenLecture.desc}</div>
      </div>
    )
  } else {
    videoContent = (
      <div className="locked-content">
        <div className="login-please">
          <LoginModal
            buttonText={
              isLoggedIn
                ? "📼Enroll Now to Watch"
                : "📼 Login/Sign up to Watch!"
            }
            isLoggedIn={isLoggedIn}
            course={course}
            slug={slug}
          />
        </div>
      </div>
    )
  }

  return (
    <Layout>
      {staticContent}

      <div className="content-container">
        <LectureList
          lectureInfo={lectureInfo}
          chooseLecture={chooseLecture}
          chosenLecture={chosenLecture}
        />
        <div className="course-content">
          <div className="lecture-title">{chosenLecture.title}</div>
          {videoContent}
        </div>
      </div>
    </Layout>
  )
}
