import React from "react"
import Button from "gatsby-theme-firebase/src/components/Button"
import "../styles/login.scss"
import { useAuth } from "gatsby-theme-firebase"

import RawLoginModal from "./sales-pages/loginModal"

const LoginModal = ({ buttonText, course, buttonClass, slug }) => {
  const [toggleLogin, setToggleLogin] = React.useState(false)
  const { isLoggedIn } = useAuth()
  return (
    <>
      <div>
        <Button
          className={buttonClass}
          onClick={() => {
            setToggleLogin(true)
          }}
        >
          {buttonText ? buttonText : "Login"}
        </Button>
      </div>
      {toggleLogin && (
        <RawLoginModal
          setToggleLogin={setToggleLogin}
          isLoggedIn={isLoggedIn}
          course={course}
          slug={slug}
        />
      )}
    </>
  )
}

export default LoginModal
